exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".HoverTileContentBlock__ImageBlock_image-wrapper{position:relative}.HoverTileContentBlock__ImageBlock_image-wrapper,.HoverTileContentBlock__ImageBlock_image-wrapper img{width:100%;height:100%;display:flex;min-height:inherit}.HoverTileContentBlock__ImageBlock_image-wrapper img{-o-object-fit:cover;object-fit:cover}.HoverTileContentBlock__ImageBlock_image-wrapper .HoverTileContentBlock__ImageBlock_text-wrapper{position:absolute;left:0;top:0;display:flex;justify-content:center;align-items:center;width:100%;height:100%;padding:16px}.HoverTileContentBlock__ImageBlock_image-wrapper .HoverTileContentBlock__ImageBlock_apply-font-color *{color:inherit}.HoverTileContentBlock__ImageBlock_image-wrapper.HoverTileContentBlock__ImageBlock_show-placeholder{background:grey;width:100%;height:100%;background:var(--placeholderColor,#f7f7f7)}", ""]);

// exports
exports.locals = {
	"image-wrapper": "HoverTileContentBlock__ImageBlock_image-wrapper",
	"imageWrapper": "HoverTileContentBlock__ImageBlock_image-wrapper",
	"text-wrapper": "HoverTileContentBlock__ImageBlock_text-wrapper",
	"textWrapper": "HoverTileContentBlock__ImageBlock_text-wrapper",
	"apply-font-color": "HoverTileContentBlock__ImageBlock_apply-font-color",
	"applyFontColor": "HoverTileContentBlock__ImageBlock_apply-font-color",
	"show-placeholder": "HoverTileContentBlock__ImageBlock_show-placeholder",
	"showPlaceholder": "HoverTileContentBlock__ImageBlock_show-placeholder"
};