exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".HoverTileContentBlock__HoverTile_wrapper{position:relative;overflow:hidden}.HoverTileContentBlock__HoverTile_wrapper,.HoverTileContentBlock__HoverTile_wrapper .HoverTileContentBlock__HoverTile_hover-container{height:100%;display:flex;min-height:inherit}.HoverTileContentBlock__HoverTile_wrapper .HoverTileContentBlock__HoverTile_hover-container{position:absolute;width:100%;left:0;top:0;z-index:1}", ""]);

// exports
exports.locals = {
	"wrapper": "HoverTileContentBlock__HoverTile_wrapper",
	"hover-container": "HoverTileContentBlock__HoverTile_hover-container",
	"hoverContainer": "HoverTileContentBlock__HoverTile_hover-container"
};